<template>
    <section class="info-chat-residente">
        <div class="row mx-0 border-bottom" style="min-height: 57px">
            <div class="col-12 my-auto">
                <p class="f-17 f-500"> Información del residente</p>
            </div>
        </div>
        <div class="row mx-0 mt-3">
            <p class="col-12 f-14 f-500">{{ residente.vivienda }}</p>
            <p class="col-12 f-14">{{ residente.tipo === 1 ? 'Residente' : 'Inmobiliaria' }}</p>
        </div>
        <div class="row mx-0 mt-3">
            <div class="col-12 mb-3">
                <div class="d-middle">
                    <div class="wh-32px d-middle-center rounded-circle mr-2 bg-email">
                        <i class="icon-email op-05" />
                    </div>
                    <div class="tres-puntos">
                        <p class="f-12 text-86"> Correo electrónico </p>
                        <p class="f-14">{{ residente.correo }}</p>
                    </div>
                </div>
            </div>
            <div class="col-12 mb-3">
                <div class="d-middle">
                    <div class="wh-32px d-middle-center rounded-circle mr-2 bg-phone">
                        <i class="icon-phone op-05" />
                    </div>
                    <div class="tres-puntos">
                        <p class="f-12 text-86">Número de celular</p>
                        <p class="f-14">{{ residente.telefono }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="div-divisor my-4" />
        <div class="row mx-0">
            <p class="col-12 f-17 f-500 mb-3">Grupos en común {{ residente.grupos.length }}</p>
            <div v-for="(g,key) in residente.grupos" :key="key" class="col-12 mb-3">
                <div class="d-middle">
                    <div class="wh-32px mr-2">
                        <img :src="g.imagen" alt="" class="w-100 obj-cover rounded-circle" />
                    </div>
                    <div class="tres-puntos">
                        <p class="f-15"> {{ g.nombre }} </p>
                        <p class="f-12 text-86 tres-puntos"> {{ g.cant_usuarios }} Residentes </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    computed:{
        ...mapGetters({
            residente:'chats/residenteActivo'
        })
    },

}
</script>
<style lang="scss" scoped>
.info-chat-residente{
    .bg-email{
        i::before{
            margin-left: 0px !important;
        }
    }
    .div-divisor{
        height: 5px;
        background: #F5F5F5;
    }
}
</style>